import * as React from 'react'
import SEO from '../components/seo'
import * as styles from './index.module.css'

import * as coverphoto from '../images/coverphoto.jpg'

import Layout from '../components/layout'
import Contact from '../components/contact'

// markup
const IndexPage = () => {
  return (
    <Layout location="/">
      <SEO title="O mnie" />
      <main className={styles.main}>
        <img src={coverphoto} alt="Me" />

        <div className={styles.bio}>
          <p>
            <b>Cześć!</b>
          </p>
          <p>
            W 2008 roku rozpoczęłam swoją przygodę z księgowością w praktyce.
            Pamiętam jak w pierwszym dniu mojej pracy poszłam (w roli słuchacza)
            na spotkanie z klientem. Pamiętam do dziś, co księgowa powiedziała
            do klienta:
          </p>
          <p>
            &ndash; Panie Jacku ta faktura to kosztem od razu nie będzie,
            zrobimy <i>peka</i>, wrzucimy w <i>eremki</i> i będzie dobrze, ale
            ta faktura &ndash; wskazując palcem na kolejną &ndash; to to w ogóle{' '}
            <i>enkupy</i> będą.
          </p>
          <p>
            Szczerze? Niewiele z tego zrozumiałam, a niestety klient rozumiał
            jeszcze mniej.
          </p>
          <p>
            Postawiłam sobie wtedy za cel stworzyć biuro rachunkowe, które
            będzie wyjaśniało klientom skomplikowane kwestie dotyczące podatków,
            przepisów i wszelkich innych regulacji w łatwy i przystępny sposób.
            Czy mi się to udało? O to trzeba zapytać moich klientów, ale mam
            wrażenie, że tak.
          </p>
          <p>
            Przez 10 lat prowadziłam biuro rachunkowe, które rozwinęłam od zera
            do 17 pracowników i ponad 300 klientów. Pomagałam rozumieć przepisy
            podatkowe nie tylko osobom prowadzącym małe firmy, ale też
            międzynarodowym korporacjom, wdrażałam dla nich wewnętrze procedury
            podatkowe, obieg dokumentów, a czasami nawet całe systemy księgowe.
            Wszystkim moim klientom, bez względu na wielkość, starałam się pomóc
            tak, żeby mogli się skupić na prowadzeniu biznesu, a nie na ciągłym
            monitorowaniu zmian w przepisach podatkowych. W końcu mają od tego
            mają nas, księgowych.
          </p>
          <p>
            I nie robię tego tylko dla pieniędzy. Ja po prostu czuję się jak
            ryba w wodzie w tym co innym wydaje się nudne &mdash; przepisy,
            podatki, ksiegowość. Uwierzcie, że przez ostatnie kilka lat naprawdę
            nie jest nudno. Co chwilę zmieniające się przepisy podatkowe
            sprawiają, że nie da się zatrzymać w miejscu. Codziennie trzeba się
            szkolić, czytać, konsultować. Takie to już nasze prawo podatkowe
            jest. Nigdy nudne, zawsze ciekawe! A o tym więcej w moim blogu.
          </p>
        </div>
        <Contact />
      </main>
    </Layout>
  )
}

export default IndexPage
